import React, { useEffect } from 'react'
import { View } from 'react-native'
import ListItem from '../../Components/ListItem'
import AnalyticsWidget from '../../Widgets/Analytics'
import Show from '../../Components/Show'
import Scaffold from '../../Components/Scaffold'
import { MobileNavbar } from '../../Components/Navbar'
import { CircleIconButton } from '../../Components/IconButton'
import {  BiUser } from 'react-icons/bi'

import { useAuthentication } from '../../Hooks/authentication'
import { useNavigation } from '../../Hooks/navigation'

import { PRIMARY_100, PRIMARY_600 } from '../../const'
import CardPaymentReportComponent from '../../Widgets/CardPaymentReportWidget'

const EmployeeHomeScreen: React.FC = () => {
  const { navigate, setCurrentStack } = useNavigation()
  const { viewer  } = useAuthentication()
  const showAnalytics = viewer?.permissions?.find(permission => permission.name == "RetreivePointGeneralFinancialAnalyticsPermissionDefinition") !== undefined || false

  const navigateToViewerAccount = () => navigate('/viewer_account')
  const handleMenuButtonPressed = () => setCurrentStack(['/customer_home'])

  return (
    <Scaffold showNavBar={false} gap={32}>
      <MobileNavbar
        label='Панель сотрудника'
        primaryButton='menu'
        primaryButtonColor={PRIMARY_100}
        primaryButtonIconColor={PRIMARY_600}
        primaryButtonOnPress={handleMenuButtonPressed}
      >
        <CircleIconButton color={PRIMARY_100} onPress={navigateToViewerAccount} >
          <BiUser size={24} color={PRIMARY_600} />
        </CircleIconButton>

      </MobileNavbar>
      <Show condition={showAnalytics}>
        <View style={{ flexDirection: 'row', gap: 8, width: '100%' }}>
          <AnalyticsWidget />
        </View>
        <CardPaymentReportComponent />
      </Show>
      <View style={{ width: '100%' }}>
        <ListItem label="Точки" onPress={() => navigate('/employee_home/points')} />
        <ListItem label="Настройки" onPress={() => navigate('/employee_home/configuration')} />
      </View>
    </Scaffold>
  )
}

export default EmployeeHomeScreen
