export const UserAuthorizationInfoQuery = `
query UserAutorizationInfo($sessionId: String!) {
	userAuthorizationInfo(userAuthorizationSessionId: $sessionId) {
    status
    verificationError
    verificationErrorDescription
    verificationMethod
  }
}
`

export const PointDetailsQuery = `
query PointDetails($id: ID!) {
  point(id: $id) {
    id
    name
    shortName
    canViewerOpenLock
    canViewerCreditCarWashTerminal
    canViewerUpdateThisPoint
    isCashlessPaymentActive
    isYandexCarwashActive
  }
}
`

export const PointsListQuery = `
query Points {
  points {
    edges {
      node {
        id
        name
        address
      }
    }
  }
}
`

export const PointLocksQuery = `
query PointLocksQuery($id: ID!) {
  point(id: $id) {
    shortName
    locks(canBeOpenedByMe: true) {
      edges {
        node {
          id
          name
          locked
          timeout
        }
      }
    }
  }
}`

export const CarWashTerminalQuery = `
query CarWashTerminal($id: ID!) {
	carWashTerminal(id: $id) {
		point {
      address
    }
    boxNumber
  }
}`

export const PointControllersQuery = `
query PointControllers($pointId: ID!) {
	point(id: $pointId) {
    shortName
    controllers {
      edges {
        node {
          name
          location
          isOnline
        }
      }
    }
  }
}`

export const PointCarWashTerminalsQuery = `
query PointCarWashTerminalsQuery($pointId: ID!) {
  point(id: $pointId) {
    shortName
    canViewerCreditCarWashTerminal
    carWashTerminals {
      edges {
        node {
          id
          boxNumber
        }
      }
    }
  }
}`

export const AnalyticsWidgetDataQuery = `
query Credits(
  $yesterdayTimestamp_Gte: DateTime
  $yesterdayTimestamp_Lte: DateTime
  $todayTimestamp_Gte: DateTime
  $todayTimestamp_Lte: DateTime
) {
  yesterdayAnalytics: tinkoffPayments(
    timestamp_Gte: $yesterdayTimestamp_Gte
    timestamp_Lte: $yesterdayTimestamp_Lte
    status: "CONFIRMED"
    test: false
  ) {
    totalCount
    totalAmount
    averageAmount
    purposeRatio {
      creditCarWashTerminal
      creditCustomerMoneyAccount
    }
  }
  todayAnalytics: tinkoffPayments(
    timestamp_Gte: $todayTimestamp_Gte
    timestamp_Lte: $todayTimestamp_Lte
    status: "CONFIRMED"
    test: false
  ) {
    totalCount
    totalAmount
    averageAmount
    purposeRatio {
      creditCarWashTerminal
      creditCustomerMoneyAccount
    }
  }
}`

export const CardPaymentReport = `
query CardPaymentReport(
  $periodTimestamp_Gte: DateTime
  $periodTimestamp_Lte: DateTime
  )
  {
  ToTerminals: carWashTerminalCredits(
    timestamp_Gte: $periodTimestamp_Gte
    timestamp_Lte: $periodTimestamp_Lte
    status: "Completed"
    source: "TINKOFF_INTERNET_ACQUIRING"
  ) {
    totalAmount
  }
  ToMoneyAccounts: moneyAccountBalanceChanges(
    timestamp_Gte: $periodTimestamp_Gte
    timestamp_Lte: $periodTimestamp_Lte
    status: "APPLIED"
    type: "INCREASE"
    reason: "PAYMENT"
  ) {
    totalAmount
  }
}`;

export const ViewerQuery = `
query Viewer {
  viewer {
    isStaff
    phoneNumber
    activeSession {
      id
      carWashTerminal {
        id
        boxNumber
      }
    }
    permissions {
      name
      payload
    }
    moneyAccounts {
      edges {
        node {
          id
          balance
          type
          bonusAccount
        }
      }
    }
  }
}`

export const CurrentSessionQuery = `
query CurrentSession {
  viewer {
    activeSession {
      id
      carWashTerminal {
        id
        boxNumber
      }
    }
  }
}`

export const PointsForSessionQuery = `
query PointsForSession {
  points(relevant: true) {
    edges {
      node {
        id
        name
        address
        carWashTerminals {
          edges {
            node {
              id
              boxNumber
            }
          }
        }
      }
    }
  }
}`

export const CarWashTerminalCreditStateQuery = `
query CarWashTerminalCredit($carWashTerminalCreditId: ID!) {
  carWashTerminalCredit(id: $carWashTerminalCreditId) {
    status
  }
}`

export const BonusProgramsConfigurationQuery = `
query BonusProgramsConfiguration {
  bonusProgramsConfiguration {
    bonusProgramForPoor {
      initialCreditAmount
      incrementCreditAmount
      activeFrom
      activeTo
    }
  }
}`

export const ViewerBonusProgramsStateQuery = `
query ViewerMoneyAccounts {
  viewer {
    bonusProgramsState {
      bonusProgramForRich {
        moneyCredited
        bonusesCredited
        currentStepBonusPercent
        nextStepBonusPercent
        nextStepMoneyAmountDistance
        highestStep
        lastBonusCreditAmount
      }
      bonusProgramForPoor {
        hasBonusCarWash
        stepMoneyAmountDistance
      }
    }
  }
}`


export const ViewerMoneyAccountsQuery = `
query ViewerMoneyAccounts {
  viewer {
    bonusProgramsState {
      bonusProgramForRich {
        moneyCredited
        bonusesCredited
        currentStepBonusPercent
        nextStepBonusPercent
        nextStepMoneyAmountDistance
        highestStep
      }
    }
    moneyAccounts {
      edges {
        node {
          id
          balance
          isActive
          type
          bonusAccount
          hasChanges
        }
      }
    }
  }
}`

export const ConfigurationQuery = `
query Configuration {
  configuration {
    canCreditCustomerMoneyAccount
    canCreditBonusProgramMoneyAccount
    canUseCustomerMoneyAccountBalance
    canUseBonusProgramMoneyAccountBalance
    advertiseBonusProgramsInCarWashPaymentForm
  }
}`

export const MoneyAccountsAnalyticsQuery = `
query Analytics(
  $yesterdayTimestamp_Gte: DateTime
  $yesterdayTimestamp_Lte: DateTime
  $todayTimestamp_Gte: DateTime
  $todayTimestamp_Lte: DateTime
) {
  todayBonusProgramForRichCreditStat: moneyAccountBalanceChanges(
    timestamp_Gte: $todayTimestamp_Gte
    timestamp_Lte: $todayTimestamp_Lte
    type: "INCREASE"
    status: "APPLIED"
    reason: "BONUS_PROGRAM_FOR_RICH"
  ) {
    totalCount
    totalAmount
    averageAmount
  }
  todayBonusProgramForRichUseStat: moneyAccountBalanceChanges(
    timestamp_Gte: $todayTimestamp_Gte
    timestamp_Lte: $todayTimestamp_Lte
    type: "DECREASE"
    status: "APPLIED"
    moneyAccount_Type: "BONUS_PROGRAM_FOR_RICH"
  ) {
    totalCount
    totalAmount
    averageAmount
  }
  todayBonusProgramForPoorCreditStat: moneyAccountBalanceChanges(
    timestamp_Gte: $todayTimestamp_Gte
    timestamp_Lte: $todayTimestamp_Lte
    type: "INCREASE"
    status: "APPLIED"
    reason: "BONUS_PROGRAM_FOR_POOR"
  ) {
    totalCount
    totalAmount
    averageAmount
  }
  todayBonusProgramForPoorUseStat: moneyAccountBalanceChanges(
    timestamp_Gte: $todayTimestamp_Gte
    timestamp_Lte: $todayTimestamp_Lte
    type: "DECREASE"
    status: "APPLIED"
    moneyAccount_Type: "BONUS_PROGRAM_FOR_POOR"
  ) {
    totalCount
    totalAmount
    averageAmount
  }
  todayCustomerCreditStat: moneyAccountBalanceChanges(
    timestamp_Gte: $todayTimestamp_Gte
    timestamp_Lte: $todayTimestamp_Lte
    type: "INCREASE"
    status: "APPLIED"
    reason: "PAYMENT"
  ) {
    totalCount
    totalAmount
    averageAmount
  }
  todayCustomerUseStat: moneyAccountBalanceChanges(
    timestamp_Gte: $todayTimestamp_Gte
    timestamp_Lte: $todayTimestamp_Lte
    type: "DECREASE"
    status: "APPLIED"
    reason: "RETAIL_CAR_WASH"
    moneyAccount_Type: "CUSTOMER"
  ) {
    totalCount
    totalAmount
    averageAmount
  }
  yesterdayBonusProgramForRichCreditStat: moneyAccountBalanceChanges(
    timestamp_Gte: $yesterdayTimestamp_Gte
    timestamp_Lte: $yesterdayTimestamp_Lte
    type: "INCREASE"
    status: "APPLIED"
    reason: "BONUS_PROGRAM_FOR_RICH"
  ) {
    totalCount
    totalAmount
    averageAmount
  }
  yesterdayBonusProgramForRichUseStat: moneyAccountBalanceChanges(
    timestamp_Gte: $yesterdayTimestamp_Gte
    timestamp_Lte: $yesterdayTimestamp_Lte
    type: "DECREASE"
    status: "APPLIED"
    moneyAccount_Type: "BONUS_PROGRAM_FOR_RICH"
  ) {
    totalCount
    totalAmount
    averageAmount
  }
  yesterdayBonusProgramForPoorCreditStat: moneyAccountBalanceChanges(
    timestamp_Gte: $yesterdayTimestamp_Gte
    timestamp_Lte: $yesterdayTimestamp_Lte
    type: "INCREASE"
    status: "APPLIED"
    reason: "BONUS_PROGRAM_FOR_POOR"
  ) {
    totalCount
    totalAmount
    averageAmount
  }
  yesterdayBonusProgramForPoorUseStat: moneyAccountBalanceChanges(
    timestamp_Gte: $yesterdayTimestamp_Gte
    timestamp_Lte: $yesterdayTimestamp_Lte
    type: "DECREASE"
    status: "APPLIED"
    moneyAccount_Type: "BONUS_PROGRAM_FOR_POOR"
  ) {
    totalCount
    totalAmount
    averageAmount
  }
  yesterdayCustomerCreditStat: moneyAccountBalanceChanges(
    timestamp_Gte: $yesterdayTimestamp_Gte
    timestamp_Lte: $yesterdayTimestamp_Lte
    type: "INCREASE"
    status: "APPLIED"
    reason: "PAYMENT"
  ) {
    totalCount
    totalAmount
    averageAmount
  }
  yesterdayCustomerUseStat: moneyAccountBalanceChanges(
    timestamp_Gte: $yesterdayTimestamp_Gte
    timestamp_Lte: $yesterdayTimestamp_Lte
    type: "DECREASE"
    status: "APPLIED"
    reason: "RETAIL_CAR_WASH"
    moneyAccount_Type: "CUSTOMER"
  ) {
    totalCount
    totalAmount
    averageAmount
  }
}`
